<template>
  <div class="text-center pb-5">
    <el-button v-print="'#printMe'" type="warning">打印</el-button>
  </div>
  <div class="text-dark mt-5" id="printMe">
    <div style="height:60px;"></div>
    <div class="container h5">
      <div class="row pb-2" style="border-bottom: 1.5px solid #add8e6">
        <div class="col-sm float-left">
          <span class="h5 font-weight-bold">成本类别：</span
          ><span>{{ formdata.category_id_t }}</span>
        </div>
        <div class="col-sm">
          <div class="float-right">
            <span class="h5 font-weight-bold">编号：</span
            ><span>{{ formdata.id_no }}</span>
          </div>
        </div>
      </div>
      <div class="row mt-5 mb-5">
        <div class="col-sm float-none text-center">
          <span class="h2 font-weight-bold">付款申请单</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm float-left mb-2">
          <span class="h5 font-weight-bold">项目名称：</span
          ><span>{{ projectname }}</span>
        </div>
        <div class="col-sm">
          <div class="float-right">
            <span class="h5 font-weight-bold">日期：</span
            ><span>{{ formdata.sq_date }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container h5">
      <table class="table text-center align-middle">
        <tr>
          <td
            class="h5 align-middle font-weight-bold"
            style="width: 16%; height: 10em; border: 1.5px solid #000"
          >
            付款说明
          </td>
          <td
            class="align-middle text-left"
            style="border: 1.5px solid #000"
            colspan="5"
          >
            {{ formdata.fk_text }}
          </td>
        </tr>
        <tr>
          <td
            class="h5 font-weight-bold"
            style="width: 16%; height: 2em; border: 1.5px solid #000"
          >
            付款金额
          </td>
          <td
            class="align-middle text-left"
            style="border: 1.5px solid #000"
            colspan="5"
          >
            <span>{{ formdata.sq_amount }}</span><span class="ml-5">{{ formdata.sq_amountd}}</span>
          </td>
        </tr>
        <tr>
          <td
            class="h5 font-weight-bold"
            style="width: 16%; height: 2em; border: 1.5px solid #000"
          >
            供应商
          </td>
          <td
            class="align-middle text-left"
            style="border: 1.5px solid #000"
            colspan="5"
          >
            {{ formdata.SupplierInfo.supplier_name }}
          </td>
        </tr>
        <tr>
          <td
            class="h5 font-weight-bold"
            style="width: 16%; height: 2em; border: 1.5px solid #000"
          >
            收款户名
          </td>
          <td
            class="align-middle text-left"
            style="border: 1.5px solid #000"
            colspan="5"
          >
            {{ formdata.SupplierBanksinfo.account_name }}
          </td>
        </tr>
        <tr>
          <td
            class="h5 font-weight-bold"
            style="width: 16%; height: 2em; border: 1.5px solid #000"
          >
            收款账号
          </td>
          <td
            class="align-middle text-left"
            style="border: 1.5px solid #000"
            colspan="5"
          >
            {{ formdata.SupplierBanksinfo.account_no }}
          </td>
        </tr>
        <tr>
          <td
            class="h5 font-weight-bold"
            style="width: 16%; height: 2em; border: 1.5px solid #000"
          >
            开户行
          </td>
          <td
            class="align-middle text-left"
            style="border: 1.5px solid #000"
            colspan="5"
          >
            {{ formdata.SupplierBanksinfo.banks }}
          </td>
        </tr>

        <tr>
          <td class="h5 font-weight-bold border-white" style="width: 16%">
            申请人:
          </td>
          <td class="border-white" style="width: 16%"></td>
          <td class="h5 font-weight-bold border-white" style="width: 16%">
            复核人:
          </td>
          <td class="h5 border-white" style="width: 16%"></td>
          <td class="h5 font-weight-bold border-white" style="width: 16%">
            审批人:
          </td>
          <td class="h5 border-white" style="width: 16%"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["fkdid"],
  data() {
    return {
      //项目名称
      projectname: window.sessionStorage.getItem("project_name"),
      formdata: {
        category_id: "",
        id_no: "",
        sq_date: "",
        fk_text: "",
        sq_amount: "",  //小写金额
        sq_amountd: "",  //大写金额
        SupplierInfo: { supplier_name: "" },
        SupplierBanksinfo: { account_name: "", account_no: "", banks: "" },
      },
    };
  },
  created() {
    this.loadtabledata(this.fkdid);
  },

  methods: {
    //加载单条记录
    loadtabledata(rowid) {
      console.log("admin/FkRequest/" + rowid);
      axios
        .get("admin/FkRequest/" + rowid, {
          params: {},
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("!@!@!@!@!@!@", response.data.data[0]);
          this.formdata = response.data.data[0];
          let xiaoxie = this.toThousands(this.formdata.sq_amount);
          let daxie = this.convertCurrency(this.formdata.sq_amount);
          this.formdata.sq_amount = xiaoxie;
          this.formdata.sq_amountd = " 金额大写：" + daxie;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //格式化数字
    toThousands(cellValue) {
      return cellValue.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //
    //代码如下所示：
    convertCurrency(money) {
      //汉字的数字
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      var cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      var cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      var cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      var cnInteger = "整";
      //整型完以后的单位
      var cnIntLast = "元";
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = "";
      //分离金额后用的数组，预定义
      var parts;
      if (money == "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
          let n = integerNum.substr(i, 1);
          let p = IntLen - i - 1;
          let q = p / 4;
          let m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        var decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
          let n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == "") {
        chineseStr += cnInteger;
      }
      return chineseStr;
    },
  },
};
</script>
<style>
</style>
