<template>
  <!-- 付款申请列表页 -->
  <div class="table-responsive mt-5">
    <div class="d-flex align-items-center">
    <!-- 左边 -->
    <span>选择日期：</span>
    <date-picker @select-change="datepickerselect"></date-picker>
      <!-- 右边 -->
      <div class="ml-auto">
        <el-button-group>
          <el-button type="success" size="mini" @click="order_status = 1"
            >待审批</el-button
          >
          <el-button type="success" size="mini" @click="order_status = 2"
            >待审核</el-button
          >
          <el-button type="success" size="mini" @click="order_status = 3"
            >待付款</el-button
          >
          <el-button type="success" size="mini" @click="order_status = 4"
            >已付款</el-button
          >
        </el-button-group>
        <el-button-group class="ml-3">
          <el-button type="success" size="mini" @click="order_status = 5"
            >未批准</el-button
          >
          <el-button type="success" size="mini" @click="order_status = 6"
            >差资料</el-button
          >
        </el-button-group>
         <el-input
          v-model="formdata.name"
          placheholder="要搜索的供应商名称"
          size="mini"
          style="width: 150px"
          class="mr-2 ml-3"
        ></el-input>
        <el-button type="info" size="mini">搜索</el-button>
      </div>
    </div>

    <el-table
      border
      class="mt-3"
      :data="tableData.data"
      style="width: 100%;"
      :show-summary="true"
      :summary-method="summaryMethod"
      height="650"
       >
      <el-table-column label="状态" width="180" align="center">
        <template #default="scope">
          <el-button
            size="small"
            type="success"
            round
            v-if="scope.row.order_status === 1"
            >待审批</el-button
          >
          <el-button
            size="small"
            type="warning"
            round
            v-if="scope.row.order_status === 2"
            >待审核</el-button
          >
          <el-button
            size="small"
            type="warning"
            round
            v-if="scope.row.order_status === 3"
            >待付款</el-button
          >
          <el-button
            size="small"
            type="danger"
            round
            v-if="scope.row.order_status === 4"
            >已付款</el-button
          >
          <el-button
            size="small"
            type="danger"
            round
            v-if="scope.row.order_status === 5"
            >未批准</el-button
          >
          <el-button
            size="small"
            type="danger"
            round
            v-if="scope.row.order_status === 6"
            >资料不全</el-button
          >
          <el-button size="small" plain @click="pdfview(scope.row.id)"
            >附件</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template #default="scope">
          <el-button-group
            v-if="
              scope.row.order_status === 1 ||
              scope.row.order_status === 5 ||
              scope.row.order_status === 6
            "
          >
            <el-button size="small" plain @click="updatespayments(scope.row.id)"
              >修改</el-button
            >

            <el-button size="small" plain type="danger">删除</el-button>
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column label="打印" width="95" align="center">
        <template #default="scope">
          <el-button
            size="small"
            type="success"
            round
            @click="callprintcompent(scope.row.id)"
            >打印</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="sq_date"
        sortable
        label="日期"
        width="100"
        align="center"
      />
      <el-table-column
        prop="id_no"
        sortable
        label="凭证编号"
        width="140"
        align="center"
      />
      <el-table-column
        prop="SupplierInfo.supplier_name"
        sortable
        label="供应商名称"
        width="240"
        align="center"
        :filters="supplierfilters"
        :filter-method="filterHandler"
      />
      <el-table-column
        prop="sq_amount"
        label="付款金额"
        width="180"
        align="center"
        :formatter="toThousands"
      />
      <el-table-column
        prop="category_id_t"
        sortable
        label="成本类别"
        width="180"
        align="center"
      />
      <el-table-column
        prop="fk_text"
        label="付款说明"
        width="180"
        align="center"
      />

      <el-table-column
        prop="Managerproposer.usealias"
        label="申请人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="Managerapprover.usealias"
        label="批准人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="Managercfo.usealias"
        label="审核人"
        width="180"
        align="center"
      />
      <el-table-column
        prop="Managerregistrant.usealias"
        label="付款人"
        width="180"
        align="center"
      />
    </el-table>
  </div>
  <!-- 打印付款申请单 -->
  <el-dialog v-model="fkdidVisible" width="100%" fullscreen>
    <print-fkd :fkdid="printfkdid"></print-fkd>
  </el-dialog>
  <!-- 查看PDF文件List -->
  <el-dialog v-model="pdfurlVisible" width="100%" fullscreen>
    <pdf-view fileclass="2" :prdclassid="prdclassid"></pdf-view>
  </el-dialog>
  <div style="height: 60px"></div>
  <!-- 占位底部60PX -->
  <el-footer
    class="border-top d-flex align-items-center px-0 position-fixed bg-white"
    style="bottom: 0; left: 150px; right: 0; z-index: 100"
  >
    <el-config-provider :locale="locale">
      <el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[5, 20, 50, 200, 500]"
        layout="sizes, prev, pager, next,total,jumper"
        :total="lotalrows"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        prev-text="上一页"
        next-text="下一页"
        background
      >
      </el-pagination
    ></el-config-provider>
  </el-footer>
</template>

<script>
import axios from "axios";
import zhCnelementpage from "element-plus/lib/locale/lang/zh-cn";
import printfkd from "../../../components/fkrequext/fkdprint.vue";
export default {
  data() {
    return {
      //供应商名称筛选过滤
      supplierfilters: [],
      //付款申请单打印
      fkdidVisible: false,
      printfkdid: "", //需打印的付款申请单ID

      //分页数据
      pageSize: 10, //每页数据 默认10条
      currentPage: 1, //当前页码  默认第1页
      lotalrows: 0, //总行数
      locale: zhCnelementpage, //中文分页
      //

      prdclassid: "", //查看附件记录的ID
      pdfurlVisible: false, //查看附件列表开关
      //
      project_id: window.sessionStorage.getItem("project_ids"),
      order_status: 1,
      //DatePicker 日期选择器
      startDate:'1900-1-1',
      endDate:'2099-12-31',
      //
      formdata: {
        name: "",
      },
      //
      tableData: {
        data: [],
      },
    };
  },
  watch: {
    order_status() {
      this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
    },
  },
  created() {
    this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
  },
  methods: {
    //DatePicker 日期选择器事件
    datepickerselect(val){
      this.startDate = val[0]
      this.endDate = val[1]
      console.log('日期选择器事件',this.startDate,this.endDate)
    },
    //选择页码选项
    handleCurrentChange(val) {
      console.log("选择页码选项", val);
      this.currentPage = val;
      this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
    },
    //选择每页条数
    handleSizeChange(val) {
      console.log("选择每页条数", val);
      this.pageSize = val;
      this.loadtabledata(this.order_status, this.pageSize, this.currentPage);
    },
    loadtabledata(status_id, rows, pags) {
      axios
        .get("admin/FkRequest", {
          params: {
            project_id: this.project_id,
            order_status: status_id,
            page: pags,
            list_rows: rows,
            startDate:this.startDate,
            endDate:this.endDate,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("***supplierfilters***", response.data.data, "******");
          this.currentPage = response.data.current_page; //当前页码  默认第1页
          this.lotalrows = response.data.total; //总行数
          this.tableData.data = response.data.data;
          this.supplierfilters = this.arrayinfdexof(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看pdf清单列表
    pdfview(vals) {
      console.log("*********", vals);
      this.prdclassid = vals;
      this.pdfurlVisible = true;
    },
    //打开修改页面
    updatespayments(rowid) {
      window.sessionStorage.setItem("rowid", rowid);
      this.$router.push({ name: "payments_spayments_update" });
    },
    //格式化数字
    toThousands(cellValue) {
      return cellValue.sq_amount.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //调用打印付款申请单组件
    callprintcompent(valid) {
      this.printfkdid = valid;
      this.fkdidVisible = true;
    },
    //表格筛选方法
    filterHandler(val, row) {
      // console.log(val,"表格筛选方法",row,column)
      // const property1 = column["property"];
      return row.SupplierInfo.supplier_name === val;
    },

    //:filters="[
    //      { text: '普票', value: '1' },
    //      { text: '专票', value: '2' },
    //    ]"
    //数据去重复  返回数组
    arrayinfdexof(prosarr) {
      let arr1 = [];
      let arr = [];
      //response.data.data[0].SupplierInfo.supplier_name
      for (let i = 0; i < prosarr.length; i++) {
        if (arr.indexOf(prosarr[i].SupplierInfo.supplier_name) === -1) {
          arr.push(prosarr[i].SupplierInfo.supplier_name);
          let objtemp = new Object();
          objtemp.value = prosarr[i].SupplierInfo.supplier_name;
          objtemp.text = prosarr[i].SupplierInfo.supplier_name;
          arr1.push(objtemp);
        }
      }
      return arr1;
    },
    //合计行
    summaryMethod(param) {
      let arr = [];
      let totalsum5 = 0;

      param.data.forEach((element) => {
        totalsum5 += element.sq_amount;
      });

      arr[0] = "合计";

      arr[6] = totalsum5.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });

      return arr;
    },
  },
  components: {
    "print-fkd": printfkd,
  },
};
</script>

<style scoped>
.el-table__footer-wrapper .cell {
  text-align: right;
  font-size: 15px;
  font-weight: bold;
}
</style>
